import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from '@material-ui/core/styles'

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CardIndex from "../components/cardIndex"

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    flexGrow: 1,
  },
  parts: {
    color: 'white',
    fontSize: `2rem`,
    fontFamily: `Merriweather,Georgia,serif`
  },
  parts2: {
    color: 'white',
    fontSize: `1.5rem`,
    fontFamily: `Merriweather,Georgia,serif`,
    paddingTop: theme.spacing(1),
  },
  link: {
    boxShadow: "none"
  }
}))

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteSummary = data.site.siteMetadata.summary
  const posts = data.allMarkdownRemark.edges
  const classes = useStyles()

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={siteSummary} />

      <Bio />

      <Typography id="parts" className={classes.parts} variant="h1" component="h1">
        <span aria-label="sheep" role="img">🥩</span> 肉の部位
      </Typography>
      <Typography id="parts" className={classes.parts2} variant="h3" component="h3">
        ・赤身霜降りのお肉
      </Typography>

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={3}
        className={classes.root}
      >
        {posts.map(({ node }) => {
          if (node.frontmatter.category === "marbled") {
            return (
              <Grid item sm={3} xs={12} key={node.fields.slug} className={classes.root}>
                <CardIndex node={node} />
              </Grid>
            )
          }
          else {
            return ""
          }
        })}
      </Grid>

      <Typography id="parts" className={classes.parts2} variant="h3" component="h3">
        ・内臓系のお肉
      </Typography>

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={3}
        className={classes.root}
      >
        {posts.map(({ node }) => {
          if (node.frontmatter.category === "hormone") {
            return (
              <Grid item sm={3} xs={12} key={node.fields.slug} className={classes.root}>
                <CardIndex node={node} />
              </Grid>
            )
          }
          else {
            return ""
          }
        })}
      </Grid>

    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        summary
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            category
            avatar {
              childImageSharp {
                fixed(width: 50, height: 50) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            cover {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
