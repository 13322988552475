import React from "react"
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import { red } from '@material-ui/core/colors'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

const useStyles = makeStyles((theme) => ({

  avatar: {
    backgroundColor: red[500],
    marginBottom: '0'
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  buttonArea: {
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    paddingBottom: theme.spacing(2),
  },
}))

const CardIndex = ({ node }) => {

  const classes = useStyles()

  const truncate = (str, len) => {
    return str.length <= len ? str: (str.substr(0, len)+"...");
  }
  const title = node.frontmatter.title || node.fields.slug

  return (
    <Tooltip title={title} arrow>
      <Link to={node.fields.slug}>
        <article>
          <Card>
            <CardMedia
              className={classes.media}
              image={node.frontmatter.cover.childImageSharp.fluid.src}
              title={title}
            />
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classes.avatar} src={node.frontmatter.avatar.childImageSharp.fixed.src} />
              }
              title={title}
              subheader={node.frontmatter.date}
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p"
                dangerouslySetInnerHTML={{
                  __html: truncate(node.frontmatter.description, 70),
                }}
              />
            </CardContent>
            <CardActions>
              <div className={classes.buttonArea}>
                <Button className="gradient-small">{`詳細ページへ`} <ArrowRightIcon/></Button>
              </div>
            </CardActions>
          </Card>
        </article>
      </Link>
    </Tooltip>
  )
}

export default CardIndex
