/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import AnchorLink from 'react-anchor-link-smooth-scroll'

import { makeStyles } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import YouTubeIcon from '@material-ui/icons/YouTube'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'
import Typography from '@material-ui/core/Typography'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5)
  },
  title: {
    fontFamily: "Merriweather,Georgia,serif",
  },
  details: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      width: "80%",
    },
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: "100%",
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  buttonArea: {
    alignItems: 'center',
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
  },
  link: {
    boxShadow: "none"
  }
}))

const Bio = () => {

  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/icon.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cover: file(absolutePath: { regex: "/main.png/" }) {
        childImageSharp {
          fluid(maxHeight: 360, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      site {
        siteMetadata {
          title
          descriptionDisplay
          author {
            name
            summary
          }
          social {
            twitter
            youtube
            instagram
          }
        }
      }
    }
  `)

  const classes = useStyles()

  const { title, descriptionDisplay, author, social } = data.site.siteMetadata
  const avatar = data.avatar
  const cover = data.cover

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5" className={classes.title} gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {descriptionDisplay}
          </Typography>
        </CardContent>
        <CardHeader
          avatar={
             <Tooltip arrow title="ジューシー（元銀行員）"><Avatar aria-label="recipe" className={classes.avatar} src={avatar.childImageSharp.fixed.src} /></Tooltip>
          }
          title={author.name}
          subheader={<a className={classes.link} rel="noopener noreferrer" href="https://yakiniku-u.ukai-mnap.co.jp" target="_blank">{author.summary}</a>}
          content={author.description}
        />
        <div className={classes.controls}>
          <a className={classes.link} href={`https://twitter.com/${social.twitter}`} rel="noopener noreferrer" target="_blank">
            <Tooltip title="Go Twitter" arrow>
              <IconButton aria-label="twitter">
                <TwitterIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </a>
          <a className={classes.link} href={`https://www.instagram.com/${social.instagram}`} rel="noopener noreferrer" target="_blank">
            <Tooltip title="Go Instagram" arrow>
              <IconButton aria-label="instagram">
                <InstagramIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </a>
          <a className={classes.link} href={`https://www.youtube.com/channel/${social.youtube}`} rel="noopener noreferrer" target="_blank">
            <Tooltip title="Go Youtube" arrow>
              <IconButton aria-label="youtube">
                <YouTubeIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </a>
        </div>
        <div className={classes.buttonArea}>
          <AnchorLink href="#parts" offset="50">
            <Button className="gradient">お肉の部位を見る<ArrowDropDownCircleIcon className="gradient-button-icon" /></Button>
          </AnchorLink>
        </div>
      </div>
      <CardMedia
        className={classes.cover}
        image={cover.childImageSharp.fluid.src}
        title={title}
      />
    </Card>
  )
}
export default Bio